// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let imageURI = [];
      let balanceOf = [];
      balanceOf[0] = await store
        .getState()
        .blockchain.smartContract.methods.balanceOf(account, 0)
        .call();
      balanceOf[1] = await store
        .getState()
        .blockchain.smartContract.methods.balanceOf(account, 1)
        .call();
      balanceOf[2] = await store
        .getState()
        .blockchain.smartContract.methods.balanceOf(account, 2)
        .call();
      balanceOf[3] = await store
        .getState()
        .blockchain.smartContract.methods.balanceOf(account, 3)
        .call();
      balanceOf[4] = await store
        .getState()
        .blockchain.smartContract.methods.balanceOf(account, 4)
        .call();
      for (let i = 0; i < 5; i++) {
        if (balanceOf[i] > 0) {
          imageURI[i] = [
            i,
            "https://arweave.net/4KEDynuxpcD3xuxSE4AE6y4ZTDOKoiXsxnn2bJMGaVI/" +
              String(i) +
              ".png?r=1",
            "#" + String(i + 1),
            balanceOf[i],
          ];
        }
      }
      console.log(imageURI, balanceOf);

      dispatch(
        fetchDataSuccess({
          imageURI,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
