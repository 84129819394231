import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { ethers } from "ethers";
import TypeWriter from "./TypeWriter";
import { css, keyframes } from "@emotion/css";

const GoogleSheetsWriter = () => {
  const [value, setValue] = useState("");
  const [message, setMessage] = useState("受付は締め切りました");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEns, setIsLoadingEns] = useState(false);
  const [messageColor, setMessageColor] = useState("yellow");
  const inputRef = useRef(null);

  useEffect(() => {
    // コンポーネントがマウントされたらinput要素にフォーカスを当てる
    inputRef.current.focus();
  }, []);
  const resolveENS = async (ensName) => {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://mainnet.infura.io/v3/6729c782606a46f380263cf73770c9b8"
    ); // InfuraのプロジェクトIDを指定
    console.dir(ensName);
    const address = await provider.resolveName(ensName);
    return address;
  };

  const handleInputChange = (event) => {
    setValue(event.target.value);
    if (event.target.value.endsWith(".eth")) {
      setIsLoadingEns(true);
      resolveENS(event.target.value)
        .then((address) => {
          setValue(address);
          setIsLoadingEns(false);
        })
        .catch((error) => {
          console.error("エラー:", error.message);
          setIsLoadingEns(false);
        });
    }
    setMessage("");
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setMessage("");
    setIsLoading(true);
    return false;
    // ETHウォレットアドレスの整形とバリデーション
    try {
      const formattedValue = ethers.utils.getAddress(value);
      setValue(formattedValue);
      // setValue(formattedValue);

      // APIエンドポイントのURLを設定します
      const apiUrl = "https://mb2023.moroga.workers.dev/"; // APIのURLに置き換える
      // ヘッダーに'X-Requested-With': 'XMLHttpRequest'を追加して、プリフライトリクエストを送信しないようにする
      const headers = {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      };
      try {
        // POSTリクエストを送信します
        const response = await axios.post(apiUrl, { value: formattedValue });

        // レスポンスの結果を表示します
        console.log(response.data.message);

        // メッセージを更新します
        // setMessage(response.data.message);
        setMessage("申請ができました。");
        setMessageColor("green");
        setIsLoading(false);
      } catch (error) {
        console.error("エラーが発生しました:", error);
        // レスポンスに"Duplicate value"が含まれていれば、メッセージを更新します
        if (error.response?.data.message === "Duplicate value") {
          setMessage("すでに登録済みのアドレスです。");
          setMessageColor("orange");
        } else {
          // エラーメッセージを更新します
          setMessage("エラーが発生しました");
          setMessageColor("red");
        }
        setIsLoading(false);
      }
    } catch (error) {
      setMessage("無効なETHウォレットアドレスです。");
      setIsLoading(false);
      setMessageColor("red");
      return;
    }
  };

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <div style={{ width: "100%" }}>
          <input
            type="text"
            className={styles.input}
            value={value}
            onChange={handleInputChange}
            ref={inputRef}
            placeholder="ウォレットアドレス(0x････････････････････････････････････････)"
            disabled={true}
          />
          {isLoadingEns && (
            <div
              style={{
                position: "fixed", // 要素を画面全体で固定位置に配置
                left: "0",
                top: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.8)", // 半透明の黒色の背景
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: "9999", // 最前面に表示するための z-index 値
              }}
            >
              <div
                style={{
                  position: "absolute", // 絶対位置の基準を変更
                  width: "100%", // ローダーとボタンの間に余白を設定
                }}
              >
                {/* ローダーの表示例（適宜カスタマイズしてください） */}
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    display: "inline",
                  }}
                >
                  <circle
                    cx="25"
                    cy="25"
                    r="20"
                    stroke="#fff"
                    strokeWidth="5"
                    fill="none"
                    strokeDasharray="3 11" // ドット線を設定
                    transform="rotate(90 25 25)" // ドット線の位置を調整
                  >
                    <animateTransform
                      attributeName="transform"
                      attributeType="XML"
                      type="rotate"
                      from="0 25 25"
                      to="360 25 25"
                      dur="10s"
                      repeatCount="indefinite"
                    />
                  </circle>
                </svg>
              </div>
              <p
                style={{
                  color: "#AAA", // 絶対位置の基準を変更
                  position: "fixed",
                  top: "60%",
                }}
              >
                ENS読み込み中・・・
              </p>
            </div>
          )}
        </div>
        <button
          type="submit"
          className={styles.button}
          style={{ position: "relative" }}
          // disabled={isLoading}
          disabled={true}
        >
          {isLoading && (
            <div
              style={{
                position: "absolute",
                left: "2rem",
                top: "50%",
                transform: "translateY(-50%)",
                marginRight: "8px", // ローダーとボタンの間に余白を設定
              }}
            >
              {/* ローダーの表示例（適宜カスタマイズしてください） */}
              <svg
                width="25"
                height="25"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="25"
                  cy="25"
                  r="20"
                  stroke="#fff"
                  strokeWidth="5"
                  fill="none"
                  strokeDasharray="3 11" // ドット線を設定
                  transform="rotate(90 25 25)" // ドット線の位置を調整
                >
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    from="0 25 25"
                    to="360 25 25"
                    dur="10s"
                    repeatCount="indefinite"
                  />
                </circle>
              </svg>
            </div>
          )}
          申請
          {isLoading && (
            <div
              style={{
                position: "absolute",
                right: "2rem",
                top: "50%",
                transform: "translateY(-50%)",
                marginLeft: "8px", // ローダーとボタンの間に余白を設定
              }}
            >
              {/* ローダーの表示例（適宜カスタマイズしてください） */}
              <svg
                width="25"
                height="25"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="25"
                  cy="25"
                  r="20"
                  stroke="#fff"
                  strokeWidth="5"
                  fill="none"
                  strokeDasharray="3 11" // ドット線を設定
                  transform="rotate(90 25 25)" // ドット線の位置を調整
                >
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    from="0 25 25"
                    to="360 25 25"
                    dur="10s"
                    repeatCount="indefinite"
                  />
                </circle>
              </svg>
            </div>
          )}
        </button>
      </form>
      <div className="response">
        {message && (
          <p style={{ color: messageColor }}>
            <TypeWriter text={message} />
          </p>
        )}
      </div>
    </div>
  );
};
const styles = {
  button: css`
    color: #fff;
    font-family: "Noto Sans JP", "Helvetica Neue", Arial,
      "Hiragino Kaku Gothic ProN", "Hiragino Sans", "BIZ UDPGothic", Meiryo,
      sans-serif;
    font-size: 1rem;
    font-weight: 700;
    background-color: #888;
    border-radius: 10rem;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    padding: 0.3rem 2rem;
    margin: 1rem auto;
    white-space: nowrap;
    width: 10rem;
    max-width: 100%;
    :hover {
      color: #fff;
      background: #0151da;
      text-align: center;
    }
    :active {
      color: #fff;
      background: #0801da;
      text-align: center;
    }
  `,
  input: css`
    color: #111;
    border-radius: 0.3rem;
    caret-color: red;
    font-size: 1rem;
    width: 100%;
    padding: 0.1rem 1rem;
    text-align: center;
    background-color: #fff;
    border-bottom: 5px solid #eee;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    margin: 1rem auto;
    max-width: 30rem;
    @media (max-width: 768px) {
      /* スマートフォンの幅に対するメディアクエリ */
      font-size: 0.9rem;
      padding: 0.1rem 0.4rem;
    }
  `,
  loaderContainer: css`
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 8px;
  `,
};
export default GoogleSheetsWriter;
