import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import CircleTextLogo from "./CircleTextLogo";
import * as md5 from "md5";
import GoogleSheetsWriter from "./components/GoogleSheetsWriter";
import TypeWriter from "./components/TypeWriter";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  color: #fff;
  font-size: 2rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledLinkedButton = styled.button`
  color: #fff;
  font-size: 2rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
`;

export const StyledButtonFree = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonvipwl = styled.button`
  padding: 10px;
  margin-left: 20px;
  border-radius: 50px;
  border: none;
  background-color: blue;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: lightblue;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--secondary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100%;
  @media (min-width: 767px) {
    width: 500px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  /* box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7); */
  /* border: 4px dashed var(--secondary); */
  /* background-color: var(--accent); */
  /* border-radius: 100%; */
  width: 120px;
  transition: width 0.5s;
`;

export const StyledImgThanks = styled.img`
  /* box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary); */
  /* background-color: var(--accent);
  border-radius: 100%; */
  width: 200px;
  @media (min-width: 900px) {
    width: 300px;
  }
  @media (min-width: 1000px) {
    width: 500px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(
    `Discordとメタマスクを接続しますか？`
  );

  const [mintAmount, setMintAmount] = useState(1);
  const [minted, setminted] = useState(0);
  const [accessToken, setAccessToken] = useState(false);
  const [tokenType, setTokenType] = useState(false);
  const [alignment, setAlignment] = useState(false);

  const [discoInfo, setdiscoInfo] = useState({
    avatar: "",
    id: "",
    username: "",
    discriminator: "",
  });

  const fragment = new URLSearchParams(window.location.hash.slice(1));

  const checkFragment = () => {
    if (fragment) {
      setAccessToken(fragment.get("access_token"));
      setTokenType(fragment.get("token_type"));
    }
  };

  const checkDiscoInfo = async () => {
    if (accessToken === false || accessToken === null) {
      window.location.replace(
        "https://discord.com/api/oauth2/authorize?client_id=1032859432687784026&redirect_uri=https%3A%2F%2Fdmal.pages.dev%2F&response_type=token&scope=identify"
      );
    }
    const discoResponse = await fetch("https://discord.com/api/users/@me", {
      headers: {
        authorization: `${tokenType} ${accessToken}`,
      },
    });
    const discoRes = await discoResponse.json();
    // console.log(discoRes);
    setdiscoInfo(discoRes);
  };

  const alignmentPost = async () => {
    let gas_id = process.env.REACT_APP_GASTOKEN;
    let token = md5(`${gas_id}${discoInfo.id}${blockchain.account}`);
    let data = { id: discoInfo.id, address: blockchain.account, token: token };
    // console.log(JSON.stringify(data));
    fetch(
      "https://script.google.com/macros/s/AKfycbxgSdZEtzaQdA3GQawx7pn8kNDDeRQNnbrxsefm9L3alkB0CPAEBTNc5AqsQnvemsYKlg/exec",
      {
        method: "POST",
        "Content-Type": "application/json",
        body: JSON.stringify(data),
      }
    )
      .then((result) => result.json())
      .then((response) => {
        // console.log(response);
        if (response.status == "updated") {
          setFeedback(`連携が更新されました。`);
          setAlignment(true);
        } else if (response.status == "added") {
          setFeedback(`連携が追加されました。`);
          setAlignment(true);
        } else {
          setFeedback(`連携がうまくいかなかったようです`);
          setAlignment(false);
        }
      })
      .catch(console.error);

    // const discoResponse = await fetch(
    //   "https://script.google.com/macros/s/AKfycbxAilKQkumx4M1bw603feIF6uvQvsxtZhf_A7scMREw-9lcc65NodyqfnIGVccBIfPePw/exec",
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       mode: "no-cors",
    //       // authorization: `${tokenType} ${accessToken}`,
    //     },
    //     body: JSON.stringify(data),
    //   }
    // );
    // const discoRes = await discoResponse.json();
    // console.log(discoRes);
    // setdiscoInfo(discoRes);
  };

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getInit = () => {
    if (blockchain.account === undefined) {
      setFeedback(`Click buy to mint your NFT.`);
      setMintAmount(1);
    }
    getData();
    checkFragment();
    // checkDiscoInfo();
  };
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };
  useEffect(() => {
    getConfig();
    getData();
  }, []);

  useEffect(() => {
    getInit();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{
          padding: ".2rem",
          backgroundColor: "#000",
        }}
        // image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.jpeg" : null}
        // image={isMobile ? "/config/images/bg.jpg" : "/config/images/bg.jpg"}
        image={isMobile ? null : null}
      >
        {/* <StyledLogo
          alt={"logo"}
          src={
            alignment
              ? "/config/images/connected.svg"
              : "/config/images/connect.svg"
          }
        /> */}
        {/* <StyledLogo alt={"logo"} src={null} /> */}
        <ResponsiveWrapper
          flex={1}
          style={{ padding: 24, background: "" }}
          test
        >
          <s.Container
            flex={6}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "rgba(0,0,0,0.7)",
              // padding: 24,
              borderRadius: 24,
              // border: "4px dashed var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            {/* <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--secondary-text)",
                backgroundColor: "var(--primary-text)",
                fontFamily: "'Suwannaphum', serif",
                margin: "0 auto",
                padding: 10,
              }}
            >
              <TypeWriter text={"-Monster baSh 2023-"} />
            </s.TextDescription> */}
            <s.TextDescription
              flex={1}
              jc={"center"}
              ai={"center"}
              style={{
                maxWidth: 300,
              }}
            >
              <img src="/config/images/Mb2023.png" />
            </s.TextDescription>
            {/* <s.SpacerMedium />
            <s.TextDescription
              flex={1}
              jc={"center"}
              ai={"center"}
              style={{
                color: "#fff",
                fontSize: "1.4rem",
              }}
            >
              {"特典NFT申請について"}
            </s.TextDescription> */}
            <s.SpacerLarge />
            <s.TextDescription
              flex={1}
              jc={"center"}
              ai={"center"}
              style={{
                color: "#fff",
                fontSize: "1rem",
                padding: 0,
                width: "100%",
                textAlign: "center",
              }}
            >
              {"NFTを受け取るためのウォレットアプリインストール方法は"}
              <a
                style={{
                  color: "#b2b2e5",
                  display: "contents",
                }}
                href="https://sound-desert.com/articles/monsterbash2023_nft_receive/detail"
                target="_blank"
              >
                こちら
              </a>
            </s.TextDescription>{" "}
          </s.Container>
        </ResponsiveWrapper>

        <ResponsiveWrapper style={{ padding: 0, margin: 0 }}>
          <s.Container
            flex={1}
            jc={"start"}
            ai={"center"}
            style={{
              color: "#fff",
              paddingRight: ".5rem",
            }}
          >
            <s.TextDescription
              flex={1}
              jc={"center"}
              ai={"center"}
              style={{
                color: "#fff",
                fontSize: "1rem",
                padding: "0 0 1rem 0",
                width: "100%",
                textAlign: "center",
              }}
            >
              {"お問い合わせは"}
              <a
                style={{
                  color: "#b2b2e5",
                  display: "contents",
                }}
                href="https://sound-desert.com/contact"
                target="_blank"
              >
                こちら
              </a>
            </s.TextDescription>
            <div style={{ display: "flex" }}>
              <p className="text">{"supported by"}</p>

              <figure className="image">
                <img
                  src="/config/images/sound-desert_logo.png"
                  style={{
                    maxWidth: 170,
                    position: "relative",
                  }}
                />
              </figure>
            </div>
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
      </s.Container>
    </s.Screen>
  );
}

export default App;
