import React, { useEffect, useState } from 'react';

const TypeWriter = ({ text }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const typingSpeed = 100; // ミリ秒単位で1文字を表示するスピードを設定

  useEffect(() => {
    if (currentIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, typingSpeed);

      // useEffectのクリーンアップ関数でタイマーをクリアしておく
      return () => clearTimeout(timer);
    }
  }, [currentIndex, text]);

  useEffect(() => {
    if(text.length == 0){
      setCurrentIndex(0);
    }
    console.log(text);
  }, [text]);

  return <div>{displayText}</div>;
};


export default TypeWriter;
